/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { string, object, bool, number, node, func } from 'prop-types';
import { connect, useSelector } from 'react-redux';

import PersonIcon from '@material-ui/icons/AccountCircle';
import TrackIcon from '@material-ui/icons/TrackChanges';
import TruckIcon from '@material-ui/icons/LocalShipping';
import BubbleChatIcon from '@material-ui/icons/QuestionAnswer';
import MapIcon from '@material-ui/icons/Room';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';

import memberDucks from '../../../../../../../../state/ducks/Member/ducks';
import { getPassportSubscriptionStatus } from '../../../../../../../../state/ducks/Passport/Passport-Selectors';
import { trackEvent as trackEventActions } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlag, getPresentationFamily } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { isBrandLink, handleBrandLinkClick } from '../../../../../../GraphqlComponents/GraphqlHeader/NavigationMenu_V2/helpers/crossBrandLinkHelper';
import { getCurrentBrandId } from '../../../../../../../../state/ducks/Common/Common-Selectors';

const LINKSTYLE_NORMAL = 'Normal';
const LINKSTYLE_ALTERNATE = 'Alternate';
const LINKSTYLE_DIVIDER = 'Divider';
// const LINKSTYLE_BUTTON = 'Button';    TODO
// const LINKSTYLE_WIDGET = 'Widget';    TODO

const {
    common: {
        commonSelectors: { getIsAuthenticatedStatus, getUserRole },
    },
} = memberDucks;

const styles = (theme) => {
    const mobileMenuBG =    theme && theme.palette && theme.palette.mobileMenuBG ? theme.palette.mobileMenuBG : null;
    return {
        toggleHeader: {
            color: theme.palette.linkMenu,
            boxSizing: 'border-box',
            display: 'flex',
            fontSize: '16px',
            textDecoration: 'none',
            position: 'relative',
            fontWeight: '300',
            padding: '12px 10px',
            width: '100%',
            backgroundColor: theme.palette.white,
        },
        item: {
            width: '80%',
            display: 'inline-block',
            padding: '0',
        },
        link: {
            color: theme.palette.linkMenu,
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            textDecoration: 'none',
            position: 'relative',
            fontWeight: '400',
            height: 'auto',
            marginLeft: '0',
            width: '95%',
            padding: '0',
        },
        linkText: {
            color: theme.palette.white,
        },
        alternateStyleLink: {
            background: mobileMenuBG ? mobileMenuBG.nonProduct : null,
            display: 'block',
            fontSize: '16px',
            textDecoration: 'none',
            position: 'relative',
            fontWeight: '400',
            height: 'auto',
            marginLeft: '0',
            width: '100%',
            padding: '10px',
            '& a': {
                color: theme.palette.white,
            },
        },
        rightIcon: {
            position: 'absolute',
            right: '24px',
            top: '4px',
        },
        spriteImgIcon: {
            paddingRight: '5px',
        },
        divider: {
            boxSizing: 'border-box',
            height: '20px',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'transparent',
            marginLeft: '10px',
        },
    };
};

const getDataTestAttribute = (brandId, url, linkStyle, itemLabel) => {
    const link = url.split('/');
    let dataTest = '';
    if (linkStyle === LINKSTYLE_ALTERNATE) {
        const toSnakeCase = itemLabel
            .split(' ')
            .join('-')
            .toLowerCase();
        dataTest = `${'nav-menu-link-'}${toSnakeCase}`;
    } else if (link[link.length - 1].indexOf('?') >= 0) {
        const removingParams = link[link.length - 1].split('?');
        dataTest = `${removingParams[0]}${'-'}${brandId}${'-'}${'link'}`;
    } else {
        dataTest = `${link[link.length - 1]}${'-'}${brandId}${'-'}${'link'}`;
    }
    return dataTest;
};

const MenuLink = ({
    itemLink,
    classes,
    tabIndex,
    children,
    handleClose,
    brandId,
    linkStyle,
    itemLabel,
    trackEvent,
    level,
    categoryLabel,
    presentationFamily,
    tracking,
}) => {
    const ffIsAttributionBrandTabsSupportEnabled  =  useSelector(getFeatureFlag('is-attribution-brand-tabs-full-support-enabled'));
    const currentBrand = useSelector((state) => getCurrentBrandId(state));
    const itemClick = (e) => {
        if (isBrandLink(itemLink)) {
            handleBrandLinkClick(e, itemLink, trackEvent, currentBrand, ffIsAttributionBrandTabsSupportEnabled);
        } else {
            trackEvent({
                eventCategory: tracking?.trackingCategory,
                eventAction: tracking?.trackingAction,
                eventLabel: tracking?.trackingLabel,
            });
            if (presentationFamily === 'flower') {
                trackEvent({
                    eventCategory: 'Menu',
                    eventAction: level === 0 ? itemLabel : categoryLabel,
                    eventLabel: level === 0 ? '' : itemLabel,
                });
            }
            handleClose();
        }
    };
    try {
        if (itemLink.indexOf('://') !== -1) {
            return (
                <a
                    href={itemLink}
                    className={classes.link}
                    tabIndex={tabIndex}
                    onClick={itemClick}
                >
                    {children}
                </a>
            );
        }
    } catch (ex) {
        console.error('Failed to build menu link.', ex);
    }

    if (itemLink && itemLink !== 'none') {
        return (
            <Link
                data-test={getDataTestAttribute(brandId, itemLink, linkStyle, itemLabel)}
                onClick={itemClick}
                to={itemLink}
                className={classes.link}
                tabIndex={tabIndex}
            >
                {children}
            </Link>
        );
    }

    return (
        <div
            aria-hidden="true"
            data-test={getDataTestAttribute(brandId.toLowerCase(), itemLink, linkStyle, itemLabel)}
            onClick={itemClick}
            className={`${classes.link} ${(brandId === 'wlf' || brandId === 'sy') && classes.linkText}`}
            tabIndex={tabIndex}
        >
            {children}
        </div>
    );
};

MenuLink.propTypes = {
    trackEvent: func.isRequired,
    level: number.isRequired,
    categoryLabel: string.isRequired,
    presentationFamily: string.isRequired,
    itemLink: string.isRequired,
    classes: object.isRequired,
    tabIndex: number.isRequired,
    children: node.isRequired,
    handleClose: func.isRequired,
    brandId: string.isRequired,
    linkStyle: string.isRequired,
    itemLabel: string.isRequired,
    tracking: object,
};

MenuLink.defaultProps = {
    tracking: {},
};

const NavMenuItem = ({
    itemLabel,
    itemLink,
    classes,
    icon,
    hasSubmenu,
    tabIndexMenu,
    isAuthenticated,
    handleClose,
    linkStyle,
    userRole,
    isPassportInCart,
    brandId,
    trackEvent,
    level,
    categoryLabel,
    presentationFamily,
    handleClick,
    tracking,
}) => {
    /* eslint object-curly-newline: "off" */
    const iconPresets = {
        person: PersonIcon,
        target: TrackIcon,
        truck: TruckIcon,
        bubble_chat: BubbleChatIcon,
        marker: MapIcon,
    };

    if (linkStyle === LINKSTYLE_DIVIDER) {
        return <div className={classes.divider} tabIndex="-1" />;
    }

    if (itemLabel === 'Sign In or Register' && isAuthenticated) {
        return null;
    }

    if (itemLabel === '[SEARCH]') {
        return null;
    }

    if ((itemLabel === 'Login' && isAuthenticated) || (itemLabel === 'Logout' && !isAuthenticated)) {
        return null;
    }

    if (
        itemLabel.includes('Join Passport')
    && ((userRole === 'P' && isAuthenticated) || isPassportInCart)
    ) {
        return null;
    }

    // icon should be "[[preset]]" or image url
    let SpriteImgIcon = null;
    if (icon) {
        const match = icon.match(/^\[\[(\S+)\]\]$/); // e.g.  '[[person]]'
        if (match) {
            SpriteImgIcon = iconPresets[match[1]];
            SpriteImgIcon = SpriteImgIcon ? <SpriteImgIcon className={classes.spriteImgIcon} /> : null;
        } else {
            SpriteImgIcon = <img src={icon} alt={itemLabel} height="20px" />;
        }
    }

    // TODO: replace this kludgery with feature flag
    if (itemLabel === 'Welcome') {
        return null;
    }

    // TODO:  button formatting when LINKSTYLE_BUTTON

    if (hasSubmenu) {
        return (
            <div
                className={
                    `${linkStyle === LINKSTYLE_ALTERNATE ? classes.alternateStyleLink : classes.toggleHeader} item-toggle-header-submenu`
                }
                tabIndex={tabIndexMenu}
            >
                {SpriteImgIcon}
                <span className={`${classes.item} full-width menu-item-submenu-link`}>{itemLabel}</span>
            </div>
        );
    }

    return (
        <div
            onClick={() => {}}
            role="menuitem"
            onKeyPress={handleClick()}
            tabIndex={tabIndexMenu}
            className={
                `${linkStyle === LINKSTYLE_ALTERNATE ? classes.alternateStyleLink : classes.toggleHeader} item-toggle-header`
            }
        >
            <MenuLink
                brandId={brandId}
                itemLabel={itemLabel}
                linkStyle={linkStyle}
                itemLink={itemLink}
                classes={classes}
                tabIndex={tabIndexMenu}
                handleClose={handleClose}
                trackEvent={trackEvent}
                level={level}
                categoryLabel={categoryLabel}
                presentationFamily={presentationFamily}
                tracking={tracking}
            >
                {SpriteImgIcon}
                <span className={`${classes.item} full-width menu-item-link`}>{itemLabel}</span>
            </MenuLink>
        </div>
    );
};

const getBrandId = (state) => state.ui.brandId.toLowerCase();

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(trackEventActions, dispatch),
});

const mapStateToProps = (state) => ({
    isAuthenticated: getIsAuthenticatedStatus(state),
    userRole: getUserRole(state),
    brandId: getBrandId(state),
    isPassportInCart: getPassportSubscriptionStatus(state),
    presentationFamily: getPresentationFamily(state),
});

NavMenuItem.propTypes = {
    itemLabel: string.isRequired,
    itemLink: string.isRequired,
    classes: object.isRequired,
    icon: string,
    hasSubmenu: bool,
    tabIndexMenu: number,
    isAuthenticated: bool.isRequired,
    handleClose: func.isRequired,
    linkStyle: string,
    userRole: string.isRequired,
    isPassportInCart: bool.isRequired,
    brandId: string.isRequired,
    trackEvent: func.isRequired,
    level: number,
    categoryLabel: string,
    handleClick: func,
    presentationFamily: string.isRequired,
    tracking: object,
};

NavMenuItem.defaultProps = {
    icon: '',
    hasSubmenu: false,
    tabIndexMenu: -1,
    linkStyle: LINKSTYLE_NORMAL,
    handleClick: () => { },
    level: 0,
    categoryLabel: '',
    tracking: {},
};

const NavMenuItemStyled = withStyles(styles)(NavMenuItem);
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavMenuItemStyled);
